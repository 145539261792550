import React, { useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import ReactModal from "react-modal";
import ConvertKitForm from "components/convertkit-form.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("https://zolostays.com/blog/wp-content/uploads/2023/11/Best-Areas-to-Stay-in-Bangalore-A-Guide-for-Youngsters-scaled.jpg");
  height: 100vh;
`;

const Description = tw.span`inline-block text-xl mt-8 text-gray-100 font-bold `;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-left lg:text-left sm:text-6xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default () => {
  const containerRef = useRef(null);

  const navLinks = [
    <NavLinks key={1}>
      {/* <NavLink href="./about">About</NavLink> */}
      {/* <NavLink href="#">FAQ</NavLink> */}
      {/* <NavLink href="#">
        Locations
      </NavLink>
      <NavLink href="#">
        Pricing
      </NavLink> */}
    </NavLinks>,
    // <NavLinks key={2}>
    //   <PrimaryLink href="/#">
    //     Hire Us
    //   </PrimaryLink>
    // </NavLinks>
  ];

  const [bgIndex, setBgIndex] = useState(0);

  const bgImgs = [
    "https://zolostays.com/blog/wp-content/uploads/2023/11/Best-Areas-to-Stay-in-Bangalore-A-Guide-for-Youngsters-scaled.jpg",
  ];

  const changeBackground = (index) => {
    if (containerRef.current) {
      // Set the background image style directly on the div
      containerRef.current.style.backgroundImage = `url('${
        bgImgs[index % bgImgs.length]
      }')`;
    }
    // setTimeout(() => {
    //   changeBackground(index + 1);
    // }, 4000);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    // changeBackground(0);
  }, [bgImgs.length]);

  const openModal = () => setDialogOpen(true);
  const closeModal = () => setDialogOpen(false);

  return (
    <>
      <ReactModal
        isOpen={dialogOpen}
        onRequestClose={closeModal}
        contentLabel="Subscribe Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            width: 700,
            height: 500,
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <ConvertKitForm></ConvertKitForm>
      </ReactModal>
      <Container ref={containerRef}>
        <OpacityOverlay />

        <HeroContainer>
          <StyledHeader links={navLinks} />
          <TwoColumn>
            <LeftColumn>
              {/* <Notification>We have now launched operations in Europe.</Notification> */}
              <Heading>
                <br />
                <span> AI Powered <br></br> Real Estate Intelligence in India.</span>
               
                
              </Heading>
              <Description
                style={{
                  maxWidth: 900,
                  lineHeight: "120%",
                  fontSize: "160%",
                  textShadow:
                    " -1px -1px 0 #333, 1px -1px 0 #333, -1px 1px 0 #333, 1px 1px 0 #333",
                }}
              >
                India's online real estate market is plagued by overwhelming information, complexity, and uninspiring listings. Livinzy introduces real estate intelligence, effortless exploration, and a showcase of truly distinctive projects.
              </Description>
              <br></br>
              <PrimaryAction
                onClick={() => {
                  // openModal();
                }}
              >
                Launching Soon
              </PrimaryAction>
            </LeftColumn>
            <RightColumn>
              {/* <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            /> */}
            </RightColumn>
          </TwoColumn>
        </HeroContainer>
      </Container>
    </>
  );
};
